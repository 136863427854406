const columns = Object.freeze([
  {
    key: 'status',
    label: 'Статус заказа',
    status: '',
    thClass: '',
    tdClass: '',
  },
  {
    key: 'source',
    label: 'Откуда заказ',
    status: '',
    thClass: '',
    tdClass: '',
  },
  {
    key: 'number',
    label: 'Номер заказа',
    status: '',
    thClass: '',
    tdClass: '',
  },
  {
    key: 'delivery_time',
    label: 'Время доставки',
    status: '',
    thClass: '',
    tdClass: '',
    sortable: true,
  },
  {
    key: 'number_daily',
    label: '№',
    status: '',
    thClass: '',
    tdClass: '',
    sortable: true,
  },
  {
    key: 'delivery_address',
    label: 'Адрес доставки',
    status: '',
    thClass: '',
    tdClass: '',
  },
  {
    key: 'delivery_comment',
    label: 'Комментарий к адресу',
    status: '',
    thClass: '',
    tdClass: '',
  },
  {
    key: 'delivery_service',
    label: 'Курьерская служба',
    status: '',
    thClass: '',
    tdClass: '',
  },
  {
    key: 'client_full_name',
    label: 'Заказчик',
    status: '',
    thClass: '',
    tdClass: '',
  },
  {
    key: 'client_phone',
    label: 'Номер заказчика',
    status: '',
    thClass: '',
    tdClass: '',
  },
  {
    key: 'recipient_full_name',
    label: 'Получатель',
    status: '',
    thClass: '',
    tdClass: '',
  },
  {
    key: 'recipient_phone',
    label: 'Номер получателя',
    status: '',
    thClass: '',
    tdClass: '',
  },
  {
    key: 'composition_foto',
    name: 'composition_foto',
    label: 'Фото композиции',
    status: '',
    thClass: '',
    tdClass: '',
  },
  {
    key: 'composition_comment',
    label: 'Комментарий к композиции',
    status: '',
    thClass: '',
    tdClass: '',
  },
  {
    key: 'postcard_text',
    label: 'Текст открытки',
    status: '',
    thClass: '',
    tdClass: '',
  },
  {
    key: 'total_price',
    label: 'Сумма к оплате',
    status: '',
    thClass: '',
    tdClass: '',
  },
  {
    key: 'delivery_price',
    label: 'Стоимость доставки',
    status: '',
    thClass: '',
    tdClass: '',
  },
  {
    key: 'pay_status',
    label: 'Статус оплаты',
    status: '',
    thClass: '',
    tdClass: '',
  },
  {
    key: 'order_proceeds',
    label: 'Выручка с заказа',
    status: '',
    thClass: '',
    tdClass: '',
  },
  {
    key: 'manager',
    label: 'Ответственный менеджер на заказ',
    status: '',
    thClass: '',
    tdClass: '',
  },
  {
    key: 'courier',
    label: 'Назначеный курьер на заказ',
    status: '',
    thClass: '',
    tdClass: '',
  },
])


const modal = {
  delete: {
    title: 'Вы действительно хотите удалить заказ',
    content: 'Вы действительно хотите удалить заказ №',
    visible: false,
    okTitle: 'Удалить',
  },
  image: {
    title: 'Фото композиции',
    visible: false,
    okTitle: 'Закрыть',
  },
  status: {
    title: 'Изменить статус',
    visible: false,
    okTitle: 'Изменить',
  },
}


export { columns, modal }

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('table-header',_vm._b({on:{"update":_vm.onUpdateFilter,"reset":_vm.onResetFilter},scopedSlots:_vm._u([{key:"filters",fn:function(){return [_c('b-col',{staticClass:"mb-1",attrs:{"lg":"2","md":"12","sm":"12"}},[_c('common-select',_vm._b({on:{"change":_vm.onUpdateFilter}},'common-select',{
            name: 'courier_id',
            value: _vm.courier_id,
            options: _vm.couriers,
            placeholder: 'Курьер',
            valueField: 'id',
            textField: 'full_name',
            disabled: _vm.courierIsDisabled,
          },false))],1)]},proxy:true}])},'table-header',{
      dateable: false,
      currentPage: _vm.page,
    },false)),_c('table-body',_vm._b({on:{"update:page-size":_vm.onPerPageChange,"update:page":_vm.onPageChange,"settings-confirm":_vm.updateTableConfig}},'table-body',{
      currentPage: _vm.page,
      pageCount: _vm.pageCount,
      pageSize: _vm.page_size,
      itemCount: _vm.itemCount,
      isColumnSettings: true,
      fields: _vm.fields,
    },false),[_c('b-table',_vm._b({staticClass:"position-relative",on:{"sort-changed":_vm.onSort},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('span',[_vm._v("Загрузка...")])],1)]},proxy:true},{key:"empty",fn:function(scope){return [_c('span',{staticClass:"text-left"},[_vm._v(_vm._s(scope.emptyText))])]}},{key:"cell(status)",fn:function(ref){
    var item = ref.item;
return [_c('b-button',_vm._b({style:({
            width: '160px'
          }),on:{"click":function($event){return _vm.onStatus(Object.assign({}, item))}}},'b-button',{
            block: true,
            variant: _vm.style.status[item.status]
          },false),[_vm._v(" "+_vm._s(_vm.orderStatus[item.status])+" ")])]}},{key:"cell(pay_status)",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.orderPaymentStatusCollection.find(function (status) { return status.value === item.pay_status; }) ? _vm.orderPaymentStatusCollection.find(function (status) { return status.value === item.pay_status; }).text : '')+" ")]}},{key:"cell(delivery_time)",fn:function(ref){
          var item = ref.item;
return [_c('p',[_vm._v(" с "+_vm._s(_vm.$dayjs(item.delivery_interval_from * 1000).format('DD MMM HH:mm'))+" ")]),_c('p',[_vm._v(" по "+_vm._s(_vm.$dayjs(item.delivery_interval_to * 1000).format('DD MMM HH:mm'))+" ")])]}},{key:"cell(source)",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.sources.find(function (source) { return source.id === item.source_id; }) ? _vm.sources.find(function (source) { return source.id === item.source_id; }).name : '')+" ")]}},{key:"cell(client_phone)",fn:function(ref){
          var item = ref.item;
return [_c('a',{attrs:{"href":("tel:+" + (item.client_phone))}},[_vm._v(_vm._s(_vm._f("VMask")(item.client_phone,'+#(###) ###-##-##')))])]}},{key:"cell(recipient_phone)",fn:function(ref){
          var item = ref.item;
return [_c('a',{attrs:{"href":("tel:+" + (item.recipient_phone))}},[_vm._v(_vm._s(_vm._f("VMask")(item.recipient_phone,'+#(###) ###-##-##')))])]}},{key:"cell(courier)",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.courier_full_name)+" ")]}},{key:"cell(manager)",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.manager_full_name)+" ")]}},{key:"cell(composition_foto)",fn:function(ref){
          var item = ref.item;
return [(item.composition_photo.length)?[_vm._l((item.composition_photo),function(photo,index){return [_c('div',{key:index,class:index < item.composition_photo.length - 1 ? 'mb-1' : '',on:{"click":function($event){return _vm.onImage(Object.assign({}, item))}}},[_c('b-img',_vm._b({on:{"click":function($event){_vm.activeImgUrl = "" + _vm.url + (photo.photo)}}},'b-img',{
                  src: ("" + _vm.url + (photo.photo)),
                  alt: '',
                  width: '100px',
                },false))],1)]})]:_vm._e()]}}])},'b-table',{
          items: _vm.routes,
          fields: _vm.fields,
          striped: _vm.striped,
          hover: _vm.hover,
          responsive: _vm.responsive,
          showEmpty: _vm.showEmpty,
          emptyText: _vm.emptyText,
          perPage: _vm.page_size,
          busy: _vm.loading,
        },false))],1),_c('common-dialog',_vm._b({on:{"confirm":_vm.onConfirm}},'common-dialog',{
      title: _vm.modal.delete.visible
        ? _vm.modal.delete.title
        : _vm.modal.image.visible
          ? _vm.modal.image.title
          : _vm.modal.status.title,
      value: _vm.modal.delete.visible || _vm.modal.image.visible || _vm.modal.status.visible,
      okOnly: _vm.modal.image.visible,
      okTitle: _vm.modal.delete.visible
        ? _vm.modal.delete.okTitle
        : _vm.modal.image.visible
          ? _vm.modal.image.okTitle
          : _vm.modal.status.okTitle,
      hideHeader: !_vm.modal.status.visible && !_vm.modal.delete.visible,
      hideFooter: !_vm.modal.status.visible && !_vm.modal.delete.visible,
      size: _vm.modal.image.visible ? 'md' : '',
      modalClass: _vm.modal.image.visible ? 'app-modal-img' : ''
    },false),[(_vm.modal.delete.visible)?[_c('p',[_vm._v(" "+_vm._s(((_vm.modal.delete.content) + " " + _vm.id + "?"))+" ")])]:_vm._e(),(_vm.modal.image.visible)?[(_vm.modal.image.visible)?[_c('div',[_c('img',{attrs:{"src":_vm.activeImgUrl}})])]:_vm._e()]:_vm._e(),(_vm.modal.status.visible)?[_c('common-select',_vm._b({on:{"change":_vm.onStatusChange}},'common-select',{
            name: 'status',
            value: _vm.form.status,
            options: _vm.delivery_status === 0 ? _vm.orderStatusCollection.filter(function (ref) {
                var value = ref.value;

                return value !== 3;
})  : _vm.orderStatusCollection,
          },false))]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
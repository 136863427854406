<template>
  <section>
    <table-header
      @update="onUpdateFilter"
      @reset="onResetFilter"
      v-bind="{
        dateable: false,
        currentPage: page,
      }"
    >
      <template #filters>
        <b-col
          lg=2
          md=12
          sm=12
          class="mb-1"
        >
          <common-select
            v-bind="{
              name: 'courier_id',
              value: courier_id,
              options: couriers,
              placeholder: 'Курьер',
              valueField: 'id',
              textField: 'full_name',
              disabled: courierIsDisabled,
            }"
            @change="onUpdateFilter"
          />
        </b-col>
      </template>
    </table-header>

    <table-body
      v-bind="{
        currentPage: page,
        pageCount,
        pageSize: page_size,
        itemCount,
        isColumnSettings: true,
        fields,
      }"
      @update:page-size="onPerPageChange"
      @update:page="onPageChange"
      @settings-confirm="updateTableConfig"
    >
      <b-table
        class="position-relative"
        v-bind="{
            items: routes,
            fields,
            striped,
            hover,
            responsive,
            showEmpty,
            emptyText,
            perPage: page_size,
            busy: loading,
          }"
        @sort-changed="onSort"
      >
        <template #table-busy>
          <div class="text-primary my-2">
            <b-spinner class="align-middle mr-1"></b-spinner>
            <span>Загрузка...</span>
          </div>
        </template>
        <template #empty="scope">
          <span class="text-left">{{ scope.emptyText }}</span>
        </template>
        <template #cell(status)="{ item }">
          <b-button
            v-bind:style="{
              width: '160px'
            }"
            v-bind="{
              block: true,
              variant: style.status[item.status]
            }"
            @click="onStatus({ ...item })"
          >
            {{ orderStatus[item.status]}}
          </b-button>
        </template>

        <template #cell(pay_status)="{ item }">
          {{ orderPaymentStatusCollection.find(status => status.value === item.pay_status)
            ? orderPaymentStatusCollection.find(status => status.value === item.pay_status).text
            : '' }}
        </template>

        <template #cell(delivery_time)="{ item }">
          <p> с {{ $dayjs(item.delivery_interval_from * 1000).format('DD MMM HH:mm') }} </p>
          <p> по {{ $dayjs(item.delivery_interval_to * 1000).format('DD MMM HH:mm') }} </p>
        </template>

        <template #cell(source)="{ item }">
          {{
            sources.find(source => source.id === item.source_id)
              ? sources.find(source => source.id === item.source_id).name
              : ''
          }}
        </template>

        <template #cell(client_phone)="{ item }">
          <a :href="`tel:+${item.client_phone}`">{{ item.client_phone | VMask('+#(###) ###-##-##') }}</a>
        </template>

        <template #cell(recipient_phone)="{ item }">
          <a :href="`tel:+${item.recipient_phone}`">{{ item.recipient_phone | VMask('+#(###) ###-##-##') }}</a>
        </template>

        <template #cell(courier)="{ item }">
          {{ item.courier_full_name }}
        </template>

        <template #cell(manager)="{ item }">
          {{ item.manager_full_name }}
        </template>

        <template #cell(composition_foto)="{ item }">
          <template v-if="item.composition_photo.length">
            <template v-for="(photo, index) in item.composition_photo">
              <div
                @click="onImage({ ...item })"
                :key="index"
                :class="index < item.composition_photo.length - 1 ? 'mb-1' : ''"
              >
                <b-img
                  v-bind="{
                    src: `${url}${photo.photo}`,
                    alt: '',
                    width: '100px',
                  }"
                  @click="activeImgUrl = `${url}${photo.photo}`"
                ></b-img>
              </div>
            </template>
          </template>
        </template>

      </b-table>
    </table-body>

    <common-dialog
      v-bind="{
        title: modal.delete.visible
          ? modal.delete.title
          : modal.image.visible
            ? modal.image.title
            : modal.status.title,
        value: modal.delete.visible || modal.image.visible || modal.status.visible,
        okOnly: modal.image.visible,
        okTitle: modal.delete.visible
          ? modal.delete.okTitle
          : modal.image.visible
            ? modal.image.okTitle
            : modal.status.okTitle,
        hideHeader: !modal.status.visible && !modal.delete.visible,
        hideFooter: !modal.status.visible && !modal.delete.visible,
        size: modal.image.visible ? 'md' : '',
        modalClass: modal.image.visible ? 'app-modal-img' : ''
      }"
      @confirm="onConfirm"
    >
      <!-- TODO move to comp -->
      <template v-if="modal.delete.visible">
        <p> {{ `${modal.delete.content} ${id}?`}} </p>
      </template>
      <template v-if="modal.image.visible">
        <template v-if="modal.image.visible">
          <div><img :src="activeImgUrl" /></div>
        </template>
      </template>
      <template v-if="modal.status.visible">
        <common-select
          v-bind="{
              name: 'status',
              value: form.status,
              options: delivery_status === 0 ? orderStatusCollection.filter(({ value }) => value !== 3)  : orderStatusCollection,
            }"
          @change="onStatusChange"
        />
      </template>
    </common-dialog>
  </section>
</template>

<script>
import { AuthNameSpace } from '@/store/modules/auth/types'
import { mapState, mapActions } from 'vuex'
import { RouteNameSpace, RouteActionTypes } from '@/store/modules/route/types'
import { StaffNameSpace, StaffActionTypes } from '@/store/modules/staff/types'
import { SourceNameSpace, SourceActionTypes } from '@/store/modules/source/types'
import { throttle } from 'lodash'
import { duration, BASE_URL } from '@/config'
import { BTable } from 'bootstrap-vue'

import TableHeader from '@/components/common/table/TableHeader'
import TableBody from '@/components/common/table/TableBody'

import { columns, modal } from '@/config/routes'
import { orderStatusCollection, OrderStatus, orderPaymentStatusCollection } from '@/config/shared'

import { tableConfigSetup, tableConfigFormatBeforeSave } from '@/mixins/table-columns-controller'

export default {
  name: 'Routes',
  mixins: [tableConfigSetup, tableConfigFormatBeforeSave],
  components: {
    BTable,
    TableHeader,
    TableBody,
    CommonSelect: () => import('@/components/common/common-select'),
    CommonDialog: () => import('@/components/common/common-dialog.vue'),
  },
  data() {
    return {
      delivery_status: 0,
      courier_id: null,
      form: {
        status: null,
        id: null,
      },
      search: '',
      date_from: '',
      date_to: '',
      page_size: 10,
      page: 1,
      modal,
      city_id: this.active_city,
      sort_by: {
        sortBy: 'interval_to',
        sortDesc: 'desc',
      },
      courierIsDisabled: false,
      loading: true,
      default_sort_by_order: 1,
    }
  },
  props: {
    striped: {
      type: Boolean,
      default() {
        return true
      },
    },
    hover: {
      type: Boolean,
      default() {
        return true
      },
    },
    responsive: {
      type: Boolean,
      default() {
        return true
      },
    },
    emptyText: {
      type: String,
      default() {
        return 'Нет данных'
      },
    },
    showEmpty: {
      type: Boolean,
      default() {
        return true
      },
    },
    url: {
      type: String,
      default() {
        return BASE_URL
      },
    },
  },
  computed: {
    ...mapState(AuthNameSpace, {
      userData: 'userData',
      active_city: 'city_id',
    }),
    ...mapState(RouteNameSpace, {
      routes: 'routes',
      config: 'config',
      pageCount: 'pageCount',
      itemCount: 'itemCount',
    }),

    ...mapState(StaffNameSpace, {
      couriers: 'staffCollection',
    }),

    ...mapState(SourceNameSpace, {
      sources: 'sources',
    }),

    fields() {
      return this.tableConfigSetup({ local_config: columns, response_config: this.config })
    },

    modalConfig() {
      return modalConfig
    },

    orderStatusCollection() {
      if (this.userData.role !== 'courier') {
        return orderStatusCollection
      } else {
        return orderStatusCollection.filter(item => item.value === 3 || item.value === 4)
      }
    },

    orderStatus() {
      return OrderStatus
    },

    orderPaymentStatusCollection() {
      return orderPaymentStatusCollection
    },

    style() {
      return {
        status: {
          0: 'light',
          1: 'success',
          2: 'info',
          3: 'warning',
          4: 'primary',
        },
      }
    },
  },

  async beforeMount() {
    await this.loadTableConfig()
    await this.loadStaffCollection()
    this.setDefaultCourier()
    await this.loadSourceCollection()
    await this.loadCollection()
  },

  methods: {
    ...mapActions(RouteNameSpace, {
      [RouteActionTypes.LoadRouteCollection]: RouteActionTypes.LoadRouteCollection,
      [RouteActionTypes.ChangeOrderStatusToCourier]: RouteActionTypes.ChangeOrderStatusToCourier,
      [RouteActionTypes.LoadRouteTableConfig]: RouteActionTypes.LoadRouteTableConfig,
      [RouteActionTypes.UpdateRouteTableConfig]: RouteActionTypes.UpdateRouteTableConfig,
    }),

    ...mapActions(StaffNameSpace, {
      [StaffActionTypes.LoadStaffCollection]: StaffActionTypes.LoadStaffCollection,
    }),

    ...mapActions(SourceNameSpace, {
      [SourceActionTypes.LoadSourceCollection]: SourceActionTypes.LoadSourceCollection,
    }),

    loadCollection: throttle(async function() {
      try {
        this.loading = true
        await this[RouteActionTypes.LoadRouteCollection]({ ...this.$data, courier_id: this.userData.role === 'courier' ? this.userData.id : this.courier_id })
      } finally {
        this.loading = false
      }
    }, duration),

    loadStaffCollection: throttle(async function() {
      await this[StaffActionTypes.LoadStaffCollection]({ page: 1, per_page: 50, role: 'courier', city_id: this.active_city })
    }, duration),

    loadSourceCollection: throttle(async function() {
      await this[SourceActionTypes.LoadSourceCollection]({ page: 1, per_page: 50 })
    }, duration),

    async loadTableConfig() {
      await this[RouteActionTypes.LoadRouteTableConfig]()
    },

    async updateTableConfig({ settings }) {
      await this[RouteActionTypes.UpdateRouteTableConfig]({ settings: this.tableConfigFormatBeforeSave({ settings }) })
    },

    async onEditModal({ id }) {
      this.$bvModal.show(this.modalConfig.modalId)
      this.modalMode.edit = true
      this.modalConfig.title = 'Изменить статус заказа'
      this.modalConfig.success = 'подтвердить'
      this.form.id = id
    },

    async onStatus({ id, status, delivery_status }) {
      this.form.id = id
      this.form.status = status
      this.delivery_status = delivery_status

      this.modal.status.visible = true
    },

    onImage({ order_id }) {
      this.id = order_id

      this.modal.image.visible = true
    },

    async onConfirm({ confirmed }) {
      if (confirmed) {
        try {
          if (this.modal.status.visible) {
            if (confirmed) {
              await this[RouteActionTypes.ChangeOrderStatusToCourier]({ ...this.form })
              await this.loadCollection()
            }
          }
        } catch {
          for (const type in this.modal) {
            if (this.modal[type].visible) {
              this.modal[type].visible = false
            }
          }
        }
      }

      for (const type in this.modal) {
        if (this.modal[type].visible) {
          this.modal[type].visible = false
        }
      }
    },

    onStatusChange(context) {
      Object.assign(this.$data.form, context)
    },

    async onUpdateFilter(context) {
      Object.assign(this.$data, context)
      this.page = 1
      await this.loadCollection(context)
    },

    async onResetFilter(context) {
      Object.assign(this.$data, context)
      this.courier_id = null

      await this.loadCollection()
    },

    async onPerPageChange(count) {
      this.page = 1
      this.page_size = parseInt(count, 10)

      await this.loadCollection()
    },

    async onPageChange(count) {
      this.page = parseInt(count, 10)

      await this.loadCollection()
    },

    async onSort({ sortBy, sortDesc }) {
      if (sortBy) {
        if (sortBy !== this.sort_by.sortBy) {
          this.sort_by.sortBy = sortBy === 'delivery_time' ? 'interval_to' : sortBy
        }
        this.sort_by.sortDesc = sortDesc ? 'desc' : 'asc'
      }

      await this.loadCollection()
    },
    setDefaultCourier() {
      if (this.userData.role === 'courier') {
        this.courier_id = this.couriers.find(item => item.id === this.userData.id).id
        this.courierIsDisabled = true
      } else {
        this.courierIsDisabled = false
      }
    },
  },
  watch: {
    async active_city(city_id) {
      this.city_id = city_id
      if (this.page > 1) this.page = 1
      await this.loadCollection()
      await this.loadStaffCollection()
    },
  },
}
</script>
